import Axios from "axios";

var cartaoPerfil = {
    methods: {
        getDadosBasicos() {
            return Axios.get(this.CriarUrl({ resource: `api/usuario/obtem-dados-basicos` }), { withCredentials: true });
        }
    }
};

export default cartaoPerfil;
