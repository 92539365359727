<template>
    <div class="cartao-container">
        <unicom-cartao class="cartao-icone">
            <div slot="body">
                <unicom-linha :espacamento="false" :justificarItem="'center'">
                    <unicom-coluna :sm="12" class="alinha-item">
                        <unicom-icone :nome="icone" :tamanho="55" />
                    </unicom-coluna>
                    <unicom-coluna :sm="12" class="alinha-item">
                        <h2 class="fonte-primaria medio negrito">{{ subtitulo }}</h2>
                    </unicom-coluna>
                </unicom-linha>
            </div>
        </unicom-cartao>
    </div>
</template>

<script>
export default {
    props: {
        icone: {
            type: String,
            required: true,
            note: "Icone que será exibido no cartão"
        },
        subtitulo: {
            type: String,
            required: true,
            note: "Subtitulo que será exibido no cartão"
        }
    }
};
</script>

<style lang="scss" scoped>
.cartao-container {
    padding: 12px 0px;

    .cartao-icone {
        width: calc(100% - 12px);
        height: 175px;
        color: #00995d;
        cursor: pointer;

        &:hover {
            box-shadow: 0px 3px 6px #dee2e6;
        }

        .alinha-item {
            text-align: center;
            margin-bottom: 16px;
        }
    }
}
</style>
