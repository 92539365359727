<template>
    <div class="cartao-container">
        <unicom-cartao class="cartao-perfil">
            <div slot="header">
                <h2 class="fonte-primaria subtitulo negrito">Meu perfil</h2>
            </div>

            <div slot="body">
                <unicom-linha :espacamento="false" class="cartao-linha">
                    <unicom-coluna>
                        <h3 class="fonte-primaria negrito">Nome</h3>
                        <p class="fonte-secundaria">{{ usuario.nome }}</p>
                    </unicom-coluna>
                </unicom-linha>

                <unicom-linha :espacamento="false" class="cartao-linha">
                    <unicom-coluna>
                        <h3 class="fonte-primaria negrito">Unimed</h3>
                        <p class="fonte-secundaria">
                            {{ usuario.codigoDescricaoUnimed }}
                        </p>
                    </unicom-coluna>
                </unicom-linha>

                <unicom-linha :espacamento="false" class="cartao-linha" v-if="exibirCargo">
                    <unicom-coluna>
                        <h3 class="fonte-primaria negrito">Cargo</h3>
                        <p class="fonte-secundaria">{{ usuario.cargo }}</p>
                    </unicom-coluna>
                </unicom-linha>

                <unicom-linha :espacamento="false" class="cartao-linha">
                    <unicom-coluna>
                        <h3 class="fonte-primaria negrito">Último acesso</h3>
                        <p class="fonte-secundaria">{{ usuario.dataUltimoAcesso }}</p>
                    </unicom-coluna>
                </unicom-linha>

                <unicom-linha :espacamento="false" class="cartao-linha" v-if="!exibirCargo">
                   
                </unicom-linha>
            </div>

            <div slot="footer">
                <unicom-botao :bloco="true" class="fonte-secundaria medio" @click="$emit('alterarDados')"
                    >ALTERAR MEU PERFIL</unicom-botao
                >
            </div>
        </unicom-cartao>
        <unicom-modal-loading v-model="loading" />
        <unicom-modal-sucesso
            v-model="mostrarSucesso"
            titulo="Os dados foram alterados com sucesso!"
            @fechar="mostrarSucesso = false"
        />
    </div>
</template>

<script>
import CartaoPerfilMixin from "./shared/services/CartaoPerfilMixin";
// import ModalAlterarDados from "../ModalAlterarDados/ModalAlterarDados";

export default {
    mixins: [CartaoPerfilMixin],
    data() {
        return {
            usuario: {
                cargo: "",
                codigoDescricaoUnimed: "",
                idUsuario: 0,
                nome: "",
                telefone: "",
                email: ""
            },
            mostrarSucesso: false,
            loading: false
        };
    },
    computed: {
        exibirCargo() {
            return this.getSession().tipoColaborador
        }
    },
    beforeMount() {
        this.loading = true;

        this.getDadosBasicos()
            .then(res => (this.usuario = { ...res.data }))
            .finally(() => (this.loading = false));
    }
};
</script>

<style lang="scss" scoped>
.cartao-container {
    padding: 12px 6px;

    .cartao-perfil {
        width: calc(100% - 12px);
        height: 360px;

        h2,
        h3 {
            color: #00995d;
        }

        p {
            margin-top: 6px;
            color: #60656c;
        }

        .cartao-linha {
            margin: 16px 0;
        }
    }
}
</style>
