<template>
    <div class="home-container">
        <unicom-linha :espacamento="false">
            <unicom-coluna>
                <h1 class="fonte-primaria negrito grande titulo-home">{{`Gestão Institucional Unimed ${ambiente}`}}</h1>
            </unicom-coluna>
        </unicom-linha>

        <unicom-linha :espacamento="false">
            <unicom-coluna :md="4" :lg="3">
                <CartaoPerfil @alterarDados="$emit('alterarDados')" @atualizar="$emit('atualizar')"/>
            </unicom-coluna>

            <unicom-coluna :md="8">
                <unicom-linha :espacamento="false">
                    <unicom-coluna :md="4" :lg="3" v-for="(item, index) in icones" :key="index">
                        <div @click="$router.push(item.rota)" v-if="canView(item)">
                            <CartaoIcone :icone="item.icone" :subtitulo="item.subtitulo" />
                        </div>
                    </unicom-coluna>
                </unicom-linha>
            </unicom-coluna>
        </unicom-linha>
    </div>
</template>

<script>
import CartaoIcone from "./shared/CartaoIcone";
import CartaoPerfil from "./shared/CartaoPerfil/CartaoPerfil";

import ColaboradorServiceMixin from "../../mixins/services/gerenciarUsuarios/ColaboradorServiceMixin";

import UsuarioServiceMixin from "../../mixins/services/usuario/UsuarioServiceMixin.js";

export default {
    mixins: [ColaboradorServiceMixin, UsuarioServiceMixin],
    components: {
        CartaoIcone,
        CartaoPerfil
    },

    data() {
        return {
            icones: [
                { icone: "monitor", subtitulo: "Suas aplicações", rota: "aplicacoes" },
                { icone: "users", subtitulo: "Gerenciar usuários", rota: "gerenciarUsuarios" },
                { icone: "monitor", subtitulo: "Gerenciar aplicações", rota: "gerenciarAplicacoes" },
                { icone: "monitor", subtitulo: "Gerenciar Conta Serviço", rota: "contaServico" },
                { icone: "clipboard", subtitulo: "Auditoria", rota: "auditoria" },
                { icone: "bell", subtitulo: "Publicar boletim" }
            ],
            usuario: null,
            ambiente: ""
        };
    },
    async beforeMount() {
        const unimeds = localStorage.getItem("unimeds");

        if (!unimeds || unimeds.length == 0)
            await this.getUnimeds()
                .then(response => this.setUnimedsList(response.data))
                .catch(() => this.setUnimedsList([]));
    },
    async mounted() {
        this.usuario = this.getSession();
        this.getDadosBasicosMenu().then(res => this.$store.commit("adicionarDadosCompleto", res.data));
        this.ambiente = this.$appConfig.config.AMBIENTE ? this.$appConfig.config.AMBIENTE : "";
    }
};
</script>

<style lang="scss" scoped>
.home-container {
    width: 1.242‬px;
    min-height: 372px;
    margin: 34px;

    .titulo-home {
        color: #00995d;
        margin: 24px 6px;
        line-height: 36px;
    }
}
</style>
