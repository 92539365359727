<template>
    <div>
        <Home @atualizar="$emit('atualizar')" @alterarDados="$emit('alterarDados')"/>
    </div>
</template>

<script>
import Home from "../components/home/Home.vue";
export default {
    components: {
        Home
    }
};
</script>

<style lang="scss" scoped></style>
